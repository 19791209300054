import React from 'react';
import Card from './Card';

const AboutUs = ({ id }) => {
  return (
    <section id={id} className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">About Us</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Overview</p>
              <p className="mt-4 text-xl">
                IYG Entertainment Private Limited is a private corporation, established on 07 May 2021. It is
                classified as non-government company and is registered at Registrar of Companies, Gwalior.
                It is involved in Motion picture, radio, television and other entertainment activities.
              </p>
              <p className="mt-4 text-xl">
                We want to help and support all these upcoming talents who want to
                create a career in entertainment industry, help them find their passion and guide them to build
                a career on which they actually love to do instead of forcing them to choose a career that they
                are least bothered about.


              </p>
              <p className="mt-4 text-xl">
              Entertainment is arguably one of the oldest industries of civilization. It’s an industry that
              never stops growing. Unfortunately, our in our country, most of the youths does not even dare
              to think to choose this as a career option. There are several reasons for that. Of course, there
              is competition, and it will always be there, but the main problem they face is, lack of a
              platform or organization that can help them, and promote them, can help them create new
              content.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;