import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import AboutUs from '../components/AboutUs';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';

const Index = () => (
  <Layout>
    <section id="top" className="pt-10 md:pt-20">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Entertainment <span className="text-red-800">Redefined</span>
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Redefining the Entertainment industry for the new generations and pionering the
            Interactive Entertainment.
          </p>
          <p className="mt-8 md:mt-12">
            <AnchorLink href="#aboutus">
              <Button size="lg">Learn More</Button>
            </AnchorLink>
          </p>
          {/* <p className="mt-4 text-gray-600">Sed fermentum felis ut cursu</p> */}
        </div>
        <div className="lg:w-1/2">
          <StaticImage
            src="../img/832_tfg_cinema.jpg"
            className="sm:hidden md:flex"
            placeholder="blurred"
            alt=""
            // layout="fullWidth"
            // height={64}
          />
          {/* <HeroImage /> */}
        </div>
      </div>
    </section>

    {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScf9OCHOpVig9rNgv24hUYyiOpNP6wjw8SBRHchvESmOYsbXg/viewform?embedded=true" width="640" height="1051" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}
    {/* <iframe width="640px" height= "480px" src= "https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAZ__vTUkFpUQlpVMzRBU1RVNUtQT1Q3RkQxVlg4UDRHUy4u&embed=true" frameborder= "0" marginwidth= "0" marginheight= "0" style={{border: 'none', maxWidth:'100%', maxHeight:'100vh'}} allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe> */}

    <AboutUs id="aboutus" />
    <section id="what-we-do" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5x//l font-semibold">What we do</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-5">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Music</p>
              <p className="text-gray-600 font-semibold text-base">Release: TBA</p>
              <p className="mt-4">
                <b>ZeroTunes</b> is an initiative to promote and support all the upcoming musicians
                or bands, but also, it’s a platform where the recording studios or any other
                recording and media organizations will have a chance to look into the profile and
                music of all the bands, and if they like any band, they can contact them. We aim to
                give all the recording facilities to find the next big band in the music industry.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Video Production</p>
              <p className="text-gray-600 font-semibold text-base">Coming Soon</p>
              <p className="mt-4">
                <b>IYG Media</b> is a supportive brand for ZeroTunes for Video Production and search
                for talent in Video Entertainment Industry.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              {/* <p className="text-gray-600 font-semibold text-base">eSports</p> */}
              <p className="font-semibold mt-1 text-2xl">E Sports</p>
              <p className="text-gray-600 font-semibold text-base">Coming Soon</p>
              <p className="mt-4">
                <b>E-warz</b> is an upcoming platform where every members of a gaming community, may
                he/she be a gamer, streamer, organizer, commentator, or broadcaster can come and
                collaborate and host amazing events, opening up new opportunity and career options
                for all the participants. E-Warz will have the list of streamers, broadcasters,
                commentators.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>
    <section id="m&v" className="pt-20 md:pt-20">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl pt-5 lg:text-5xl font-semibold">Our Mission & Vision</h2>
      </div>
      <SplitSection
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-3xl font-semibold leading-tight">Market Analysis</h3>
            <p className="mt-8 text-xl font-light leading-relaxed">
              Our team of enthusiastic marketers will analyse and evaluate how your company stacks
              against the closest competitors
            </p>
          </div>
        }
        secondarySlot={<SvgCharts />}
      />
    </section>
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Design And Plan Your Business Growth Steps
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Once the market analysis process is completed our staff will search for opportunities
            that are in reach
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Search For Performance Optimization
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            With all the information in place you will be presented with an action plan that your
            company needs to follow
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <section id="contact-us" className="">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Ready to grow?</h2>
        <section className="container mx-auto mt-20 p-16 bg-gray-200 rounded-lg text-center">
          <h6 className="text-5xl font-semibold">Contact Us</h6>
          <p className=" mb-3 text-xl font-light">
            <h6 className="font-semibold">IYG ENTERTAINMENT PRIVATE LIMITD</h6>
          </p>
          {/* <p className=" mb-3 text-xl font-light">
            Plot No. 76 D, Udyog Vihar Phase - 4, Sector-18 Gurugram, Haryana - 122001
          </p> */}
          {/* <p className=" mb-3 text-xl font-light">
            Complaint/Customer care:{' '}
            <a className="font-bold" href="mailto:mailto:support@iyg.co.in">
              support@iyg.co.in
            </a>
          </p> */}
          <p className=" mb-3 text-xl font-light">
            business query:{' '}
            <a className="font-bold" href="mailto:mailto:founder@iyg.co.in">
              founder@iyg.co.in
            </a>
          </p>
          {/* <p className=" mb-3 text-xl font-light">
            Compliance/Taxing:{' '}
            <a className="font-bold" href="mailto:mailto:compliance@iyg.co.in">
              compliance@iyg.co.in
            </a>
          </p>
          <hr className="my-5" />
          <div className="flex flex-col items-center justify-center">
            <iframe
              title="Feedback Form"
              className="freshwidget-embedded-form w-full md:w-auto"
              id="freshwidget-embedded-form"
              src="https://iyg.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&formTitle=Contact+us+via+form+below&submitTitle=Sent+message+&submitThanks=Thankyou+for+your+message++&searchArea=no"
              scrolling="no"
              height="500px"
              width="50%"
              frameborder="0"
            ></iframe>
          </div> */}
        </section>
      </div>
    </section>
  </Layout>
);

export default Index;
